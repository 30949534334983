@tailwind base;
@tailwind components;
@tailwind utilities;

/* PACKAGE STYLES */
@import "react-phone-number-input/style.css";

/* FONTS */
@font-face {
  font-family: "ProximaNova-Thin";
  src: url("../assets/fonts/ProximaNova-Thin.woff");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("../assets/fonts/ProximaNova-Light.woff");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("../assets/fonts/ProximaNova-Regular.woff");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../assets/fonts/ProximaNova-Semibold.woff");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../assets/fonts/ProximaNova-Bold.woff");
}

@font-face {
  font-family: "ProximaNova-Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabold.woff");
}

.container {
  max-width: 170vh;
  padding: 0vh 2vh;
}

.container-full-width {
  max-width: 100%;
}

/* CUSTOM UNDERLINE */
.custom-underline {
  position: relative;
}

.custom-underline::after {
  content: "";
  position: absolute;
  background-color: #f07d1b;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -1px;
  transition: 0.4s;
}

.custom-underline:hover:after {
  width: 100%;
}

.custom-underline.white::after {
  background-color: #fff;
  bottom: -3px;
  height: 1px;
}

/* HEADER */
.main-header {
  box-shadow: 0px 3px 5px rgba(57, 63, 72, 0.2);
}

.main-header.slide-in {
  animation: slideIn 0.5s ease-in;
}

.error.PhoneInput .PhoneInputInput {
  border: 1px solid rgba(229, 115, 115, 1);
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

body::-webkit-scrollbar {
  display: none;
}

/* PHONE INPUT STYLES */
.PhoneInputInput {
  font-family: "ProximaNova-Semibold", sans-serif;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0.8vh 1.7vh;
  font-size: 1.6vh;
}

.PhoneInputInput::placeholder {
  font-family: "ProximaNova-Regular", sans-serif;
}

.PhoneInputCountry {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 1vh;
}

/* REACT SELECT */
.subject__control {
  border: 1px solid #e5e5e5 !important;
  font-family: "ProximaNova-Semibold", sans-serif;
  padding: 0.1vh 1.5vh;
}

.subject__control .subject__value-container .subject__placeholder {
  font-family: "ProximaNova-Regular", sans-serif;
  font-weight: 400 !important;
  color: #d1d0d0;
  font-size: 1.6vh;
}

.subject__control .subject__value-container .subject__single-value {
  font-size: 1.6vh;
  font-weight: 400;
  color: #000;
}

.subject__control .subject__indicators .subject__indicator-separator {
  display: none;
}

.subject__menu-list {
  font-family: "ProximaNova-Semibold", sans-serif;
}

.subject__menu-list .subject__option {
  font-size: 1.6vh;
}


/* SELECT ERROR */
.subject_error__control {
  border: 1px solid #E57373 !important;
  font-family: "ProximaNova-Semibold", sans-serif;
  padding: 0.1vh 1.5vh;
}

.subject_error__control .subject_error__value-container .subject_error__placeholder {
  font-family: "ProximaNova-Regular", sans-serif;
  font-weight: 400 !important;
  color: #d1d0d0;
  font-size: 1.6vh;
}

.subject_error__control .subject_error__value-container .subject_error__single-value {
  font-size: 1.6vh;
  font-weight: 400;
  color: #000;
}

.subject_error__control .subject_error__indicators .subject_error__indicator-separator {
  display: none;
}

.subject_error__menu-list {
  font-family: "ProximaNova-Semibold", sans-serif;
}

.subject_error__menu-list .subject_error__option {
  font-size: 1.6vh;
}
